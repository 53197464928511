import multiplyPermutations from "@firefighting-game/processing-module/helpers/multiplyPermutations";
import {
    FGButtonActionType,
    FGGameState,
    FGGameStateAction,
    LocationProps,
} from "../types";
import { locationNamePermutationMapping } from "../helpers/initializeGameState";
import { calculateNewFires } from "../helpers/fireGenerator";
import { calculateAcresBurned, fightFire } from "../helpers/fireDynamics";

function gameStateReducer(state: FGGameState, action: FGGameStateAction) {
    let newDaysInLocation;
    const newTurnCounter = state.turnCounter + 1;
    const newAcresBurned = state.acresBurned + calculateAcresBurned(state.map);
    let newFireMap = calculateNewFires(state.map, state.turnCounter);
    switch (action.type) {
        case FGButtonActionType.PERMUTE:
            const newCurrentPermutation = multiplyPermutations(
                state.currentPermutation,
                action.payload
            );

            const newLocationName =
                locationNamePermutationMapping[
                    JSON.stringify(newCurrentPermutation)
                ];

            newFireMap.locations = newFireMap.locations.map(
                (location: LocationProps) => {
                    if (location.name === state.currentLocation) {
                        return {
                            ...location,
                            isCurrentLocation: false,
                        };
                    } else if (location.name === newLocationName) {
                        return {
                            ...location,
                            isCurrentLocation: true,
                        };
                    } else {
                        return location;
                    }
                }
            );

            newDaysInLocation = 1;
            const newLocationState: FGGameState = {
                currentLocation: newLocationName,
                currentPermutation: newCurrentPermutation,
                daysInLocation: newDaysInLocation,
                turnCounter: newTurnCounter,
                map: newFireMap,
                acresBurned: newAcresBurned,
            };
            return newLocationState;

        case FGButtonActionType.FIGHTFIRE:
            newDaysInLocation = Math.min(state.daysInLocation + 1, 6);

            newFireMap.locations = newFireMap.locations.map(
                (location: LocationProps) => {
                    if (location.name === state.currentLocation) {
                        return {
                            ...location,
                            fireSeverity: fightFire(
                                state.daysInLocation,
                                location.fireSeverity
                            ),
                        };
                    } else {
                        return location;
                    }
                }
            );

            const newFirefightState: FGGameState = {
                currentLocation: state.currentLocation,
                currentPermutation: state.currentPermutation,
                daysInLocation: newDaysInLocation,
                turnCounter: newTurnCounter,
                map: newFireMap,
                acresBurned: newAcresBurned,
            };
            return newFirefightState;
        default:
            throw new Error("Invalid action type");
    }
}

export { gameStateReducer };
